@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-color: rgb(26, 201, 201);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}




.home {
	min-height: 100vh;
	background-color: rgb(47, 46, 70);
	color: white;
	display: flex;
	justify-content: space-around;
	align-items: center;
}


.center {
	text-align: center;
	margin-top: 5%;
}

h1 {
	font-family: 'Permanent Marker', cursive;
}

.center button {
	margin-left: 1%;
}
