@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.center {
	text-align: center;
	margin-top: 5%;
}

h1 {
	font-family: 'Permanent Marker', cursive;
}

.center button {
	margin-left: 1%;
}